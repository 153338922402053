import React, {StrictMode} from 'react';
import {createInertiaApp, ReactComponent} from '@inertiajs/inertia-react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {createRoot} from 'react-dom/client'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ChakraProvider, ColorModeScript} from '@chakra-ui/react'
import theme from "@/app/layouts/theme/index";

const queryClient = new QueryClient();


createInertiaApp({
    resolve: (name: string) =>
        resolvePageComponent<ReactComponent>(
            `./app/pages/${name}.tsx`,
            // @ts-ignore
            import.meta.glob('./app/pages/**/*.tsx'),
        ),
    setup: function ({el, App, props}) {
        createRoot(el).render(
            <StrictMode>
                <QueryClientProvider client={queryClient}>
                    <ChakraProvider>
                        <ColorModeScript initialColorMode={theme.config.initialColorMode}/>
                        <App {...props} />
                    </ChakraProvider>
                </QueryClientProvider>
            </StrictMode>
        )
    },
});
